@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}
html{
    // font-size: 62.5%;
    scroll-behavior: smooth;
}

.main-container{
    width: 96%;
    margin: auto;
}

//colors
$arnikoRed: #F15D5A;
$black: #000000;
$white: #ffffff;
$grayscale: #262338;
$arnikoBlue:#2A3152;

//@mixin
@mixin image-fit($fitValue){
    height: 100%;
    width: 100%;
    object-fit: $fitValue;
}

.main-button-red{
   outline: none;
   border: 2px solid $arnikoRed;
   background-color: $arnikoRed;
   color: $white;
   border-radius: 5px;
   a{
    display: block;
    font-size: 16px;
    color: $white;
    padding: 8px 14px;
   }
}

.main-button-red-transparent{
    outline: none;
    border: 2px solid $arnikoRed;
    background-color: transparent;
    color: $arnikoRed;
    border-radius: 5px;
    a{
     font-size: 16px;
     display: block;
     color: $arnikoRed;
    padding: 8px 14px;
    }
 }
.title-component{
    font-size: 32px;
    font-weight: 600;
    color: $arnikoRed;
}
.header-para-section{
      .welcome-desc{
          font-size: 16px;
          font-weight: 400;
          width: 60%;
          margin: 40px auto 0;
      }
}


.navbar{
    height: 84px;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: $white;
    .logo{
        height: 80%;
        width: 200px;
        img{
            @include image-fit(cover);
        }
    }
    .hamburger{
        display: none;
        height: 70%;
        width: 30px;
        img{
            @include image-fit(contain);
        }
    }
    .nav-link{
        padding: 10px 2px;
        border: 2px solid $black;
        border-radius: 25px;
        font-size:16px;
        text-transform: capitalize;
        color:$black;
        font-weight: 600;
        transition: .4s ease-in;
        position: relative;
        overflow: hidden;
        &::after{
          content: '';
          position: absolute;
          background-color: $arnikoRed;
          height: 100%;
          width: 0%;
          left: 0;
          border-radius: 22px;
          transition: .4s ease-in;
          z-index: -1;
        }
        &:hover::after{
            width: 100%;
        }
        &:hover{
            border: 2px solid $arnikoRed;
            color: $white;
        }
    } 
}

@media(max-width:991px){
.hamburger{
    display: block !important;
    cursor: pointer;
}
.navbar .nav-items{
    display: none;
}
.nav-items-show{
    top:80px;
    left: 0;
    position: absolute;
    display:  flex !important;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    .nav-item{
        margin-bottom: 2px;
        a{
           &:hover{
            color: $black;
           }
        }
    }
}
}

.arniko-home{
    .video-intro-section{
        position: relative;
        height: 100vh;
        iframe{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    @media (max-width:991px) {
        .video-intro-section{
            height: 60vh;
        } 
    }
    @media (max-width:768px) {
        .video-intro-section{
            height: 40vh;
        } 
    }
    .welcome-to-arniko-college{
        padding-top: 78px;
         text-align: center;
        
        .arniko-faculties{
            padding: 78px 0 0 0;
            .faculty-box{
                width: 24.5%;
                .image-section{
                    height: 30vh;
                    width: 100%;
                    position: relative;
                    .faculty-image{
                       @include image-fit(cover);
                    }
                    .faculty-name{
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0.8);
                        font-size: 20px;
                        font-weight: 500;
                        text-transform: capitalize;
                        padding: 10px 0;
                        z-index: 5;
                    }
                    .faculty-overlay{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: 10px;
                        color: white;
                        transform: translateY(-100px);
                        transition: .55s ease-out;  
                        transition-delay: .1s;
                        opacity: 0;
                        background-color: #fcf7f7cc;
                        height: 100%;
                        width: 100%;
                    }
                    &:hover .faculty-overlay{
                        opacity: 1;
                        transform: translateY(0);
                        transition: all 0.55s ease-out;
                    }
                    &:hover .faculty-name{
                        background-color: $arnikoRed;
                        color: $white;
                    }
                }
                .faculty-paragraph-section{
                    width: 80%;
                    margin: 0 auto;
                    font-size: 16px;
                    font-weight: 500;
                    color: $grayscale;
                    margin-top: 15px;
                }
            }
            @media(max-width:991px){
                .faculty-box{
                    width: 49.5%;
                    margin-bottom: 1rem;
                }
            }
            @media(max-width:568px){
                .faculty-box{
                    width: 100%;
                }
            }
        }
    }
    .life-at-arniko{
        padding-top: 28px;
       .life-at-arniko-box{
        height: 35vh;
        width: 25%;
        a{
            height: 100%;
            width: 100%;
        }
        img{
            @include image-fit(cover);
        }
       }
       @media (max-width:'991px') {
        .life-at-arniko-box{
            width: 50%;
        }
       }
       @media (max-width:'568px') {
        .life-at-arniko-box{
            width: 100%;
        }
       }
    }
    .why-arniko-section {
        padding-top: 78px;
        .reason-box{
            width: 32%;
            margin-bottom: 40px;
            text-align: left;
            .title{
                font-size: 18px;
                font-weight: 600;
                color:$grayscale;
                margin-bottom: 12px;
            }
            .desc{
                font-size: 16px;
                font-weight: 400;
                color: $grayscale;
            }
        }
        @media (max-width:991px) {
            .reason-box{
                width: 49.5%;
            }
        }
        @media (max-width:568px) {
            .reason-box{
                width: 100%;
            }
        }
    }
    .arniko-details-section{
        padding-top: 78px;
        .details-box{
            background-color: $arnikoRed;
            width: 24.8%;
            padding: 30px;
            .icon{
                height: 100px;
                width: 100px;
                img{
                    @include image-fit(contain);
                }
            }
            .value{
                font-size: 64px;
                font-weight: 400;
                color: $white;
            }
            .title{
                font-size: 20px;
                font-weight: 400;
                color: $white;
            }
        }
    }
    .arniko-gallery{
        padding: 78px 0;
        .title-section{
            text-align: center;
            margin-bottom: 40px;
        }
        .gallery {
            display: flex;
            flex-wrap: wrap;
            }
        
            .gallery-item {
            flex: 1 0 24rem;
            margin: 1rem;
            box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
            overflow: hidden;
            }
        
            .gallery-image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 400ms ease-out;
            }
        
            .gallery-image:hover {
            transform: scale(1.15);
            }
        
            @supports (display: grid) {
            .gallery {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
                grid-gap: 8px;
            }
        
            .gallery,
            .gallery-item {
                margin: 0;
                border-radius: 5px;
            }
            }
    }
    .arniko-map{
        height: 50vh;
        width: 100%;
        img{
            @include image-fit(cover);
        }
        .scroll-to-top{
            bottom:0px;
            right:25%;
            left:48%;
            margin-left:-20px;
            height: 4rem;
            width: 10rem;
            // padding: 2px 0;
            // border-radius: 50%;
            background-color:$arnikoBlue;
            img{
                @include image-fit(contain);
            }
            &:hover{
                background-color: $arnikoRed;
            }
        }
    }
}

// footer{
//     padding: 5rem 0;
//     background-color: $arnikoBlue;
//     color: $white;
//     .logo-section{
//         width: 25%;
//         .logo{
//             width: 50%;
//             // height: 15rem;
//             img{
//                 @include image-fit(contain);
//             }
//             p{
//                 font-size: 1.8rem;
//                 font-weight: 500;
//                 margin-top: 1rem;
//             }
//         }
//     }
//     .quick-links, .it-partners, .robotics-partners{
//         h3{
//             font-size: 2px;
//             font-weight: 600;
//         }
//         a{
//             font-size: 1.6rem;
//             font-weight: 600s;
//             transition: .5s ease;
//             &:hover{
//                 color: $arnikoRed;
//             }
//         }
//     }
//     .it-partners, .robotics-partners{
//         .it-partners-logo{
//             height: 8rem;
//             width: 8rem;
//             img{
//                 @include image-fit(contain);
//             }
//         }
//     }
//     @media (max-width:991px) {
//         .logo-section,.quick-links, .it-partners, .robotics-partners{
//             width: 50%;
//             margin-bottom: 1rem;
//         }
//     }
//     @media (max-width:768px) {
       
//         .logo-section,.quick-links, .it-partners, .robotics-partners{
//             width: 100%;
//         }
//     }
// }
// .copy-right{
//     height: 5vh;
//     color: $white;
//     background: $grayscale;
//     padding: 1rem;
//     font-size: 1.6rem;
// }



//about
.about-hero-section{
    height: 85vh;
    width: 100%;
    background: url('./assets//images/aboutbg.png') no-repeat center;
    background-size: cover;
    color: $white;
    overflow: hidden;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height:100%;
        width: 100%;
        background: #2b3152;
        opacity: .89;
        z-index: 1;
    }
   
    .about-title{
        width: 65%;
         margin: 0 auto;
        font-size: 60px;
        font-weight: 800;
        z-index: 2;
    }
    .desc{
        width: 65%;
         margin: 0 auto;
        font-size: 22px;
        font-weight: 500;
        z-index: 2;
    }
    .sub-desc{
        width: 65%;
         margin: 0 auto;
        font-size: 17px;
        font-weight: 400;
        z-index: 2;
    }
    .btn-section{
        z-index: 2;
       
            a{
                color: $white;
                font-size: 18px;
                text-transform: capitalize;
                font-weight: 500;
                padding: 10px 20px;
            background-color: $arnikoRed;
            border-radius: 10px;
            }
    }
  
}
@media (max-width:991px) {
    .about-hero-section{
      .about-title{
          font-size: 40px;
        } 
      .desc{
          font-size: 18px;
      }
      .sub-desc{
          font-size: 16px;
      } 
      .btn-section{
            a{
                font-size: 14px;
            }
      }  
    }
  }
  @media (max-width:568px) {
    .about-hero-section{
      .about-title{
          font-size: 25px;
        } 
      .desc{
          font-size: 16px;
      }
      .sub-desc{
          font-size: 14px;
      } 
      .btn-section{
            a{
                font-size: 12px;
            }
      }  
    }
  }
.vision{
    padding-top: 78px;
    .mission-section{
        margin-top: 30px;
        .image-section{
            width: 50%;
            img{
                @include image-fit(cover);
            }
        }
        .mission-contents{
            width: 50%;
            padding-right: 40px;
            // margin-left: 3rem;
            .title{
                font-size: 25px;
                font-weight: 600;
                color: $arnikoRed;
                margin-bottom: 20px;
            }
            p{
                font-size: 18px;
            }
            .item{
                margin: 10px 0;
                padding: 5px 5px 5px 0;
                list-style-type: none;
                // border: 1px solid gray;
                font-size: 18px;
                font-weight: 600;
                border-radius: 5px;
                .icon{
                    margin-top: 5px;
                    height: 20px;
                    width: 20px;
                    margin-right: 20px;
                    img{
                        @include image-fit(contain);
                    }
                }
            }
        }
        @media (max-width:991px) {
            .image-section, .mission-contents{
                width: 100%;
            }
            .mission-contents{
             margin-top: 20px;
             padding-right: 0;
             padding-bottom: 40px;
            }
        }
    }
}
.arniko-video-tour{
padding-top: 78px;
  height: 50vh;
  width: 100%;
  iframe{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
}
.meet-our-team{
    padding: 78px 0;
    .team-members{
        width: 70%;
        margin:40px auto 0;
        .image-box{
            width: 31%;
            margin-bottom: 40px;
            background-color: re;
            .image-div{
                height: 30vh;
                width: 100%;
                img{
                    @include image-fit(contain);
                }
            }
            .name-person{
                margin: 10px 0;
                font-size: 20px;
                font-weight: 500;
                text-transform: capitalize;
            }
            .position{
                font-size: 16px;
                font-weight: 400;
                text-transform: capitalize;
            }
        }
        @media (max-width:991px) {
            .image-box{
                width: 48%;
            }
        }
        @media (max-width:600px) {
            .image-box{
                width: 100%;
            }
        }
    }
}

.top-navbar{
    height: 5vh;
    display: flex;
    align-items: center;
    font-size: 14px;
}


.courses-hero-section{
    .hero-section-contents{
        height: 60vh;
        padding: 50px 0;
        .desc{
            width: 50%;
            padding-right: 30px;
            .name{
                font-size: 40px;
                font-weight: 500;
            }
            p{
                font-size: 16px;
                font-size: 400;
            }
        }
        .faculty-img{
            width: 50%;
            height: 100%;
            img{
                @include image-fit(cover);
            }
        }
    }
    @media (max-width:991px) {
        .hero-section-contents{
        .desc{
            width: 100%;
        }
        .faculty-img{
         display: none !important;
        }
    }
   }
}

.our-core-values {
    padding-top: 78px;
    .core-box{
        width: 25%;
        padding: 50px;
        // height: 36vh;
        color: $white;
        .img{
            height: 60px;
            width: 60px;
            img{
                @include image-fit(contain);
            }
        }
        .title{
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
        }
        .desc{
            margin-top: 10px;
            font-size: 14px;
            font-weight: 400;
        }

    }
    @media (max-width:991px) {
        .core-box{
            width: 50%;
            // height: 40vh;
        }
    }
    @media (max-width:768px) {
        .core-box{
            width: 100%;
            // height: auto;
        }
    }
}

.model-section{
    padding: 78px 0;
    .model-contents{
        .desc{
            width: 50%;
            h2{
                color: $arnikoRed;
                font-size: 20px;
                font-weight: 500;
                margin-top: 40px;
            }
            p, li{
                font-size: 16px;
                font-weight: 400;
            }
        }
        .img{
            width: 50%;
            height: 50vh;
            img{
                @include image-fit(cover);
            }
        }
    }
    @media(max-width:991px){
        .model-contents{
            .desc{
                width: 100%;
                
            }
            .img{
                display: none !important;
            }
        }
    }
}

.about-us-button{
    padding: 7px 20px;
    border: 1px solid $grayscale;
    border-radius: 20px;
} 
.hide-component{
    display: none;
}

@media (max-width:1040px){
    .about-us-button{
        width: fit-content;
    }
    .arniko-form-button{
        display: none;
    }
    .hide-component{
        display: flex;
    }
}