@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
	margin: 0;
	padding: 0;
}

html::-webkit-scrollbar {
	width: 10px;
	background-color: rgb(164, 163, 163);
}

html::-webkit-scrollbar-thumb {
	background-color: grey;
}
.button {
	position: absolute;
	left: 50%;
	top: 0%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: 0.5s ease;
}

.button-div:hover > .button {
	/* visibility: visible; */
	opacity: 1;
	top: 45%;
	left: 50%;
}

.button:hover > .button-div {
	opacity: 0.5;
}
