

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

* {
margin: 0;
padding: 0;
font-family: 'Poppins', 'sans-serif';
scroll-behavior: smooth;
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Podkova:wght@400;500;600;700;800&display=swap');


.welcome-section-arniko{
padding: 20px 0;
margin: 0 40px;
font-family: 'Podkova', serif;
.arniko-image-section, .welcome-section-describe{
    // height: 60vh;
    width: 50%;
    .college-name{
        margin: 12px 0;
        font-weight: 800;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media(max-width:'1024px'){
    .arniko-image-section, .welcome-section-describe{
        width: 100%;
    }
}
}

.our-vision{
background-color:#383e5d;
padding: 100px 0;
.vision-content{
    width: 80%;
    .title{
        color: #eb423d;
    }
    .vision-desc{
        margin: 10px 0;
    }
    .our-mission{
        border-radius: 10px;
        margin: 20px 0;
        background-color: rgb(71, 78, 114);
        padding: 30px;
        .mission-desc{
            width: 70%;
        }
        .mission-image{
            width: 30%;
            height: 20vh;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        @media (max-width:1024px) {
            .mission-desc{
                width: 100%;
            }
            .mission-image{
                margin-top: 10px;
                width: 100%;
                height: 20vh;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
}

.arniko-in-action{
padding: 100px 0;

    .gallery {
    display: flex;
    flex-wrap: wrap;
    /* Compensate for excess margin on outer gallery flex items */
    margin: -1rem -1rem;
    }

    .gallery-item {
    /* Minimum width of 24rem and grow to fit available space */
    flex: 1 0 24rem;
    /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
    margin: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    overflow: hidden;
    }

    .gallery-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 400ms ease-out;
    }

    .gallery-image:hover {
    transform: scale(1.15);
    }

    @supports (display: grid) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
        grid-gap: 8px;
    }

    .gallery,
    .gallery-item {
        margin: 0;
        border-radius: 5px;
    }
    }

}